import React from 'react';
import { Loader } from '../loader/Loader';

const TableGarage = ({ tableData, isLoading }) => {
  const baseUrl = window.location.origin;

  const tableBody = tableData.map((item, index) => {
    const rzut = new URL(`./static/rzuty/apartamenty-poziomkowa-${item.rzut}.jpg`, baseUrl);

    return (
      <tr key={item.id} className={`${item.houseStatus === 'sold' ? 'garage-table__highlighted' : ''}`}>
        <td>{item.id}</td>
        <td>{item.garageStatus}</td>
        <td>{item.price}</td>
        <td>
          <a className="garage-table__link" href={`${rzut}`} target="_blank">
            POBIERZ
          </a>
        </td>
      </tr>
    );
  });

  return (
    <div className="garage-table__wrapper">
      <table className="garage-table">
        <thead>
          <tr className="garage-table__header">
            <th>Miejsce nr.</th>
            <th>Status</th>
            <th>Cena</th>
            <th>Rzut</th>
          </tr>
        </thead>
        {!isLoading ? <tbody>{tableBody}</tbody> : null}
      </table>
      <p className="main-table__info">*podane ceny są cenami brutto</p>
      {isLoading ? (
        <div className="garage-table__loader-wrapper">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default TableGarage;
