import React, { useState } from 'react';
import imageMap from '../../assets/map.jpg';

export const ImageMapSvg = ({ houseHoverHandler, houseLeaveHandler, houseClickHandler }) => {
  return (
    <svg
      id="poziomkowaMap"
      viewBox="0 0 2000 1277"
      x="0px"
      y="0px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="image-map__poziomkowa-map"
    >
      <image overflow="visible" width="2000" height="1277" xlinkHref={imageMap}></image>
      <path
        d="M1130.5 183.5L1063 235V301.5L1092.5 310.5V336.5L1039.5 346.5L1080 399L1162 422.5L1182.5 417.5V391L1203 385.5V343L1191 340.5V334.5V268L1196 264.5L1258 218.5L1467.5 177.5L1425.5 128.5L1130.5 183.5Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="D2"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M1160 513L1085 489.5V407.5L1081 400.5L1162 424L1184 418.5V432.5L1202 437.5V502.5L1160 513Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="D1"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M1336.5 465L1185.5 424.5V392.5L1205.5 387.5V341L1193 338.5V268.5L1261.5 218.5L1556.5 161.5L1612 227L1590.5 215.5L1479 190L1461 280V437.5L1336.5 465Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="C2"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M1336.5 565.5L1185.5 522.5V511L1203.5 506.5V475.5V436.5L1185.5 431.5V427L1336.5 468.5L1461 439.5V535.5L1336.5 565.5Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="C1"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M546.5 609.5L429.5 572.5V542L487.5 530V504.5L442.5 490V424L504.5 372L833 309L873 359.5L637.5 407L574 457V530L582.5 532.5V574.5L561.5 580V606L546.5 609.5Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="B2"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M546.5 709L561 705.5V674L582 669V630L561 623.5V608.5L546.5 612.5L429 575.5V582L486.5 601.5V618.5L427.5 632.5V667L546.5 709Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="B1"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M686.5 651.5L563.5 612.5V581.5L584 576V531.5L575.5 528V456.5L638 408.5L967 342L1033 429.5L1007 434.5V576L686.5 651.5Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="A2"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M686.5 656.5L563.5 617V622.5L584 628.5V671L563.5 676V710L686.5 751.5L1008 673V581L686.5 656.5Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="house"
        data-house-number="A1"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M505 738V699.5V697L546 712L554 709.5L685.5 754.5L764 736L934.5 695V781.5L765.5 826.5L639.5 781.5H629L505 738Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="garage"
        data-house-number="AB"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <path
        d="M1160.5 514.5L1098.5 495V543L1226 580L1226.5 583L1368 627L1460 604V538L1336 568L1183 523.5V508.5L1160.5 514.5Z"
        className="image-map__item"
        opacity="0"
        fill="#588157"
        data-type="garage"
        data-house-number="CD"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
    </svg>
  );
};
