import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ImageMapCloud } from './ImageMapCloud';
import { ImageMapSvg } from './ImageMapSvg';
import { Loader } from '../loader/Loader';

const imageMapContainer = document.getElementById('image-map__container');

export const ImageMap = ({ housesData, isLoading }) => {
  const baseUrl = window.location.origin;

  const [cloudData, setCloudData] = useState({
    id: '',
    houseArea: 0,
    price: '-',
    houseStatus: '',
    isVisible: false,
    top: 0,
    left: 0
  });

  const houseClickHandler = e => {
    const houseUrl = new URL(`./static/rzuty/apartamenty-poziomkowa-${e.target.dataset.houseNumber}.jpg`, baseUrl);
    window.open(houseUrl, '_blank').focus();
  };

  const houseHoverHandler = e => {
    if (housesData.length > 0 && e.target.dataset.type === 'house') {
      const houseData = housesData.filter(elem => {
        return elem.id === e.target.dataset.houseNumber;
      });

      setCloudData({
        id: houseData[0].id,
        type: 'house',
        houseArea: houseData[0].houseArea,
        price: houseData[0].price,
        houseStatus: houseData[0].houseStatus,
        isVisible: true,
        top: e.clientY > 450 ? e.pageY - 50 : e.pageY - e.clientY + 400,
        left: e.pageX + 50
      });
    } else if (e.target.dataset.type === 'garage') {
      setCloudData({
        id: e.target.dataset.houseNumber,
        type: 'garage',
        houseArea: '-',
        price: '-',
        houseStatus: '-',
        isVisible: true,
        top: e.clientY > 450 ? e.pageY - 50 : e.pageY - e.clientY + 400,
        left: e.pageX + 50
      });
    }
  };

  const houseLeavehandler = e => {
    setCloudData({ ...cloudData, isVisible: false });
  };

  return ReactDOM.createPortal(
    <>
      {!isLoading ? <ImageMapCloud cloudData={cloudData} /> : null}
      <div className="image-map__wrapper">
        {isLoading ? (
          <div className="image-map__loader-wrapper">
            <Loader />
          </div>
        ) : null}
        <ImageMapSvg
          houseHoverHandler={houseHoverHandler}
          houseLeaveHandler={houseLeavehandler}
          houseClickHandler={houseClickHandler}
        />
      </div>
    </>,
    imageMapContainer
  );
};
