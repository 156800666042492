import { createRoot } from 'react-dom/client';
import React, { useState, useEffect } from 'react';
import { getHousesData } from '../common/api/getHousesData';
import Table from './table/Table';
import TableGarage from './tableGarage/TableGarage';
import { ImageMap } from './imageMap/ImageMap';

const sortByLetters = (a, b) => {
  const aLetter = a.id[0];
  const bLetter = b.id[0];

  if (aLetter < bLetter) {
    return -1;
  } else if (aLetter > bLetter) {
    return 1;
  }
  return 0;
};

const sortByNumbers = (a, b) => {
  const aNumber = a.id[a.id.length - 1];
  const bNumber = b.id[b.id.length - 1];

  return aNumber - bNumber;
};

// balconyArea
// floor
// houseArea
// houseStatus
// id
// price

function App() {
  const [housesData, setHousesData] = useState([]);
  const [garageData, setGarageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getHousesData()
      .then(res => {
        if (res.length > 0) {
          const sortedData = res.sort(sortByNumbers).sort(sortByLetters);
          const sortedHouses = sortedData.filter(item => item._type === 'apartment');
          const sortedGarages = sortedData.filter(item => item._type === 'garage');
          setHousesData(sortedHouses);
          setGarageData(sortedGarages);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <ImageMap housesData={housesData} isLoading={isLoading} />
      <Table tableData={housesData} isLoading={isLoading} />
      <h4 className="garage-table__mobile-title">TABELA GARAŻY</h4>
      <TableGarage tableData={garageData} isLoading={isLoading} />
    </>
  );
}

const root = createRoot(document.getElementById('main-table'));
root.render(<App />);
