import React from 'react';

export const ImageMapCloud = ({ cloudData }) => {
  return (
    <>
      {cloudData.isVisible && (
        <div className="cloud-container" style={{ top: cloudData.top, left: cloudData.left }}>
          <div className="cloud-container__top-container">
            <div className="cloud-container__number-container">
              <p className="cloud-container__number">{`${
                cloudData.type === 'house' ? 'APARTAMENT' : 'GARAŻ BUDYNEK'
              } - ${cloudData.id}`}</p>
            </div>
          </div>
          <>
            {cloudData.type === 'house' ? (
              <div className="cloud-container__bottom-container">
                <p className="cloud-container__info">{`Powierzchnia ${cloudData.houseArea} m2`}</p>
                <p className="cloud-container__info">{`Cena ${cloudData.price} zł`}</p>
                <p className="cloud-container__status-info">
                  Status: <span className="cloud-container__status">{`${cloudData.houseStatus}`}</span>
                </p>
              </div>
            ) : null}
          </>
        </div>
      )}
    </>
  );
};
